import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {  Form } from 'react-bootstrap';
import { updateInvoiceLocal } from '../../store/actions';
import { useDispatch } from 'react-redux';

/**make sure the earliest year is current academic year
 * 
 * if current date is befroe July 1, academic year is current year -1
 * Otherwise current year
*/
function generateDates(academicYear, invoiceAcademic){
    if (invoiceAcademic-1 >academicYear){
    return  [
        // {
        //     display: `Jul 1, ${+invoiceAcademic }`,
        //     value: +invoiceAcademic-1
        // },
        {
            display: `Jul 1, ${+invoiceAcademic-1}`,
            value: +invoiceAcademic 
        },
        {
            display: `Jul 1, ${+invoiceAcademic }`,
            value: +invoiceAcademic + 1
        },
        {
            display: `Jul 1, ${+invoiceAcademic + 1}`,
            value: +invoiceAcademic + 2
        },
        {
            display: `Jul 1, ${+invoiceAcademic + 2}`,
            value: +invoiceAcademic + 3
        }

    ];
    }
    else{
        return [
           
            {
                display: `Jul 1, ${+invoiceAcademic-1}`,
                value: +invoiceAcademic
            },
            {
                display: `Jul 1, ${+invoiceAcademic}`,
                value: +invoiceAcademic + 1
            },
            {
                display: `Jul 1, ${+invoiceAcademic + 1}`,
                value: +invoiceAcademic + 2
            },
            {
                display: `Jul 1, ${+invoiceAcademic + 2}`,
                value: +invoiceAcademic + 3
            },
            {
                display: `Jul 1, ${+invoiceAcademic + 3}`,
                value: +invoiceAcademic + 4
            }

        ];
    }
}

const TermDates = () => {
    const dispatch = useDispatch();
     const invoice = useSelector((state) => state.order.invoice);
    // const orders = useSelector((state) => state.order.siteOrdered);
    const [startDateOptions, updateStartDateOptions] = useState([]);
    const [termDisplay, updateTermDisplay] = useState('');
    const years = [ 1, 2, 3, 4, 5];
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth =today.getMonth();
    const currentAcademicYear =currentMonth>=5 ? currentYear : currentYear - 1; 
   
    useEffect(() => {       
 
        const startDates = generateDates(currentAcademicYear, invoice.academic_year);
        
        updateStartDateOptions(startDates);
        const activeStartDate = startDates.find(x => x.value === +invoice.academic_year);
        updateTermDisplay(`${activeStartDate.display} - Jun 30, ${+activeStartDate.value + +invoice.license_term - 1}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoice]);

    const handleUpdate = (e) => {
         dispatch(updateInvoiceLocal({ [e.target.name]: e.target.value } ));
        //updateLicenseYear
        // dispatch(updateLicenseYear({ [e.target.name]: e.target.value }));
    }
    return (
        <>
           
        <Form>
            <div className="card">
                <div className="row">
                <div className="col-sm">
                        <Form.Group controlId='invoiceStartDate'>
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control 
                                as="select"
                                name='academic_year'
                                value={+invoice.academic_year}
                                onChange={handleUpdate}
                                >                
                                {startDateOptions && startDateOptions.map((startDate, index) => (
                                    <option value={startDate.value} key={`academic-${startDate.value}-year`}>{startDate.display}</option>
                                ))}                    
                            </Form.Control>
                        </Form.Group>
                    </div>                    
                    <div className="col-sm">
                    <Form.Group controlId='invoiceLicenseTerm'>
                        <Form.Label>Years</Form.Label>
                            <Form.Control 
                                as="select"
                                name='license_term'
                                value={invoice.license_term}
                                onChange={handleUpdate}
                                >                
                                {years.map((year, index) => (
                                    <option key={index}>{year}</option>
                                ))}                    
                            </Form.Control>
                        </Form.Group>  
                    </div>
                    <div className="col-sm">
                        <Form.Group controlId='termDates'>
                            <Form.Label>License Valid</Form.Label>
                            <Form.Control 
                                type="text"                    
                                placeholder={ termDisplay }
                                disabled
                                >
                            </Form.Control>
                        </Form.Group>
                    </div>
                </div>
            </div>
        </Form>
        </>
    )
}
export { TermDates };

