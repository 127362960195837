

import { useEffect } from 'react';
import {
    useNavigate,
    Outlet,
    useParams
} from "react-router-dom";

  import { useDispatch, useSelector } from 'react-redux';
  import { fetchOrderForm } from '../store/actions';
const BootstrapForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { urlKey='ncis' } = useParams();
    const { invoice, isValidForm } = useSelector((state) => state.order);
    
    if (urlKey !== 'ncis' && urlKey !== 'azcis' && urlKey !== 'wacis') {
        urlKey ='ncis';
    }
    useEffect(() => {

        if (urlKey !== invoice.url_key  ) {
           
            //fetch init action with redux feature
            dispatch(fetchOrderForm(urlKey));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [urlKey])

    useEffect(() => {
        if (invoice.url_key) {
            if (invoice.is_site_form) {
                navigate(`/${invoice.url_key}/sites`);
            } else {
                navigate(`/${invoice.url_key}/services`);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoice.url_key])

    useEffect(() => {
        if(!isValidForm) {
            navigate(`/`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValidForm])    

    return (
        <div className="container">
        {(urlKey !== invoice.url_key) ? (
            <div className="d-flex flex-column align-items-center">
                <span className="full-page-load">
                    <div className="lds-facebook">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>                
                </span>
            </div>
        ) : (
            <Outlet />
        )}
        
        </div>
    )
}

export { BootstrapForm };